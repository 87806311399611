import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import SectionHeading from '../components/SectionHeading'
import { Section, InnerMedium } from '../components/Sections'
import TeacherItem from '../components/TeacherItem'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import TrialSection from '../components/pageSections/Trial'

const TeachersPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="講師紹介　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="モバプロの講師を紹介します。経験豊富な日本人講師が、英会話の基礎力の底上げをサポートします。"
      pathName={pathname}
    />
    <SectionHeading bgColor="dark">
      講師紹介
    </SectionHeading>
    <Section>
      <InnerMedium>
        <TeacherItem
          index={0}
          data={{
            englishName: 'Yoko Suzuki',
            texts: [
              '某都市銀行にてSE職約10年を経験。',
              'システム統合、預金関連のシステム開発に携わる。出産退職後は大学受講予備校に勤め、数学講師として現在も活躍中。',
              '業務の傍ら独学で英語の勉強を始めるが、TOEICのスコアが徐々に上がっても一向に話せるようにならない現実に意気消沈。”どうしたら英語が話せるようになるか？”その後も試行錯誤を繰り返し悪戦苦闘の結果、辿り着いた結論が”場面の映像化と英文の作り方を理解する”。この独自メソッドで多くの方に”英語を話せる”を体現して頂きたいと考えている。',
            ],
          }}
        >
          <StaticImage
            alt="yoko-sensei"
            src="../images/teachers/yoko-sensei.png"
            quality={80}
          />
        </TeacherItem>
        <TeacherItem
          index={1}
          data={{
            englishName: 'Itaru Satori',
            texts: [
              '日本の電機・IT企業で海外支社5ヵ国（米国・英国・ドイツ・フィンランド、デンマーク）での駐在を経験。アフリカを除くほぼ全ての地域とビジネス経験あり。会社設立や新規事業立ち上げ、弁護士、会計事務所、採用等の手続きや新規ビジネスでの商談を行うなどのビジネスシーンでも英語を使った経験が豊富。',
              '英語学習にマジックは無いと思っています。必ず時間と努力が必要となります。但し、正しいメソッドとその人に合った方法で学習していけば、必ず成果が表れ、最終的には「聞く、話す、書く」といったレベルが格段に上がっていきます。後はそれに強い意志、どうしても出来る様になるという思いが加われば鬼に金棒です。',
              'これまでの電機・IT企業勤務で培った経験と本校レッスンの独自メソッドを融合し、多くの方の期待に応えていきたいと思います。',
            ],
          }}
        >
          <StaticImage
            alt="satori-sensei"
            src="../images/teachers/satori-sensei.png"
            quality={80}
          />
        </TeacherItem>
      </InnerMedium>
    </Section>
    <TrialSection />
  </Layout>
)

TeachersPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

TeachersPage.defaultProps = {
  location: null,
}

export default TeachersPage
