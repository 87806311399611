import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import media from 'styled-media-query'
import color from '../constants/colors'

const ItemStyled = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 50px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.lessThan('medium')`
    flex-direction: column;

    &:nth-child(2n) {
      flex-direction: column-reverse;
    }
  `}
`

const ImageWrapperStyled = styled.div`
  width: 260px;

  ${media.lessThan('medium')`
    max-width: 180px;
    width: 80%;
  `}
`

const Body = styled.div`
  color: ${color.heading};
  flex: 1;
  padding: 0 50px;
`

const Name = styled.p`
  font-size: 3.8rem;
  font-weight: lighter;
  margin-bottom: 25px;
  text-align: left;

  ${media.lessThan('medium')`
    text-align: center;
  `}
`

const EnglishName = styled.span`
  font-family: 'Helvetica Neue', sans-serif;
  font-style: italic;
`

const Text = styled.p`
  text-align: left;

  ${media.lessThan('medium')`
    max-width: 480px;
  `}
`

const TeacherItem = ({
  children,
  data,
  index,
}) => (
  <>
    <ItemStyled>
      {index % 2 === 0 && (
        <ImageWrapperStyled
          data-sal="fade"
          data-sal-delay="200"
          data-sal-duration={1000}
        >
          { children }
        </ImageWrapperStyled>
      )}
      <Body>
        <Name>
          <EnglishName>{ data.englishName }</EnglishName>
        </Name>
        {data.texts.map((text) => (
          <Text key={text.slice(0, 10)}>{ text }</Text>
        ))}
      </Body>
      {index % 2 !== 0 && (
        <ImageWrapperStyled
          data-sal="fade"
          data-sal-delay="200"
          data-sal-duration={1000}
        >
          { children }
        </ImageWrapperStyled>
      )}
    </ItemStyled>
  </>
)

TeacherItem.propTypes = {
  children: PropTypes.node,
  data: PropTypes.shape({
    englishName: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.string),
  }),
  index: PropTypes.number,
}

TeacherItem.defaultProps = {
  children: null,
  data: null,
  index: 0,
}

export default TeacherItem
